import { render, staticRenderFns } from "./SettingPassword.vue?vue&type=template&id=751e50cc&scoped=true&"
import script from "./SettingPassword.vue?vue&type=script&lang=js&"
export * from "./SettingPassword.vue?vue&type=script&lang=js&"
import style0 from "./SettingPassword.vue?vue&type=style&index=0&id=751e50cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751e50cc",
  null
  
)

export default component.exports