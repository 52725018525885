<template>
	<div class="SettingMobile">
		<div class="road flex align-center justify-center pr00">
			<div class="line"></div>
			<div class="r r_1 flex flex-direction align-center justify-center">
				<div
					:class="{ active: road >= 1 }"
					class="flex align-center justify-center fs16 fw-7 font-ff"
				>
					1
				</div>
				<span class="mt16 fs14 font-33">验证身份</span>
			</div>
			<div class="r r_2 flex flex-direction align-center justify-center">
				<div
					:class="{ active: road >= 2 }"
					class="flex align-center justify-center fs16 fw-7 font-ff"
				>
					2
				</div>
				<span class="mt16 fs14 font-33">绑定新手机</span>
			</div>
			<div class="r r_3 flex flex-direction align-center justify-center">
				<div
					:class="{ active: road >= 3 }"
					class="flex align-center justify-center fs16 fw-7 font-ff"
				>
					<span class="iconfont">&#xe604;</span>
				</div>
				<span class="mt16 fs14 font-33">验证身份</span>
			</div>
		</div>
		<div v-if="road == 1" class="road-1 mt32">
			<div class="item flex align-center">
				<span class="item-name">手机号码：</span>
				<div class="item-div">
					{{ userInfo._mobile }}
				</div>
			</div>
			<div class="item flex align-center">
				<span class="item-name">请输入验证码：</span>
				<div class="item-div flex">
					<el-input
						@change="btn"
						style="flex: 1"
						v-model="code"
						maxlength="6"
						placeholder="请输入验证码"
						clearable
					/>
					<button @click="getVerify" class="ml16">
						{{ codeText }}
					</button>
				</div>
			</div>
			<div class="item flex align-center">
				<span class="item-name"></span>
				<div class="item-div btn">
					<el-button :loading="loading" @click="btn">下一步</el-button>
				</div>
			</div>
		</div>
		<div v-if="road == 2" class="road-2 mt32">
			<div class="item flex align-center">
				<span class="item-name">输入新手机：</span>
				<div class="item-div flex">
					<el-input
						style="flex: 1"
						v-model="newMobile"
						maxlength="11"
						placeholder="请输入手机号"
						clearable
					/>
					<button @click="newGetVerify" class="ml16">
						{{ newCodeText }}
					</button>
				</div>
			</div>
			<div class="item flex align-center">
				<span class="item-name">请输入验证码：</span>
				<div class="item-div">
					<el-input
						@change="newBtn"
						v-model="newCode"
						maxlength="6"
						placeholder="请输入验证码"
						clearable
					/>
				</div>
			</div>
			<div class="item flex align-center">
				<span class="item-name"></span>
				<div class="item-div btn">
					<el-button :loading="newLoading" @click="newBtn">提交</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "SettingMobile",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
		codeText() {
			let { date } = this;
			if (date == 60) {
				return "获取验证码";
			} else {
				return date + "S 后重新获取";
			}
		},
		newCodeText() {
			let { newDate } = this;
			if (newDate == 60) {
				return "获取验证码";
			} else {
				return newDate + "S 后重新获取";
			}
		},
	},

	data() {
		return {
			road: 1, // 当前第几步
			codeLoading: false, // 是否获取验证码中
			code: "", // 验证码
			loading: false, // 是否请求数据中
			Timer: null, // 定时器
			date: 60, // 时间

			newMobile: "", // 新手机号
			newCodeLoading: false, // 是否获取验证码中 新的
			newCode: "", // 验证码 新的
			newLoading: false, // 是否请求数据中 新的
			newTimer: null, // 定时器 新的
			newDate: 60, // 时间 新的
		};
	},
	methods: {
		newBtn() {
			let { newLoading, newCode, newMobile } = this;

			if (newLoading) {
				return false;
			}

			if (!newMobile || !this.$validate(newMobile, "phone")) {
				this.$message.warning("请输入正确的手机号");
				return false;
			}

			if (!newCode || newCode.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}

			this.newLoading = true;
			this.$API
				.PostUserUpdateMobile({
					mobile: newMobile,
					code: newCode,
					type: 4,
				})
				.then((res) => {
					if (res.code == 1) {
						this.road = 3;
						this.$message.success("修改完成，请重新登录");
						this.$store.commit("logout", {});
						this.$router.push({ name: "Login" });
					} else {
						this.newLoading = false;
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.newLoading = false;
					console.log("err", err);
				});
		},
		btn() {
			let { userInfo, loading, code } = this;
			let { _mobile: mobile } = userInfo;

			if (loading) {
				return false;
			}

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}

			this.loading = true;
			this.$API
				.postUserCheckVerifyCode({
					mobile,
					code,
					type: 4,
				})
				.then((res) => {
					if (res.code == 1) {
						this.road = 2;
					} else {
						this.loading = false;
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 获取验证码
		newGetVerify() {
			console.log(1);
			let { userInfo, newCodeLoading, newDate, newMobile } = this;
			let { id: user_id } = userInfo;

			if (newCodeLoading || newDate != 60) {
				return false;
			}

			if (!newMobile || !this.$validate(newMobile, "phone")) {
				this.$message.warning("请输入正确的手机号");
				return false;
			}

			this.newCodeLoading = true;

			this.$API
				.SendVerifyCode({
					mobile: newMobile,
					type: 5,
					user_id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.Timer = setInterval(() => {
							this.newDate = this.newDate - 1;

							if (this.newDate <= 0) {
								this.newDate = 0;
								clearInterval(this.Timer);
								this.newDate = 60;
							}
						}, 1000);

						if (this.$store.state.ISDEV == 1) {
							this.newCode = res.data.code.toString();
						}

						this.$message.success("发送成功");
						this.newCodeLoading = false;
					} else {
						this.$message.error(res.msg);
						this.newCodeLoading = false;
					}
				})
				.catch((err) => {
					this.newCodeLoading = false;
					console.log("err", err);
				});
		},
		// 获取验证码
		getVerify() {
			let { userInfo, codeLoading, date } = this;
			let { _mobile: mobile, id: user_id } = userInfo;

			if (codeLoading || date != 60) {
				return false;
			}

			this.codeLoading = true;

			this.$API
				.SendVerifyCode({
					mobile,
					type: 4,
					user_id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.Timer = setInterval(() => {
							this.date = this.date - 1;

							if (this.date <= 0) {
								this.date = 0;
								clearInterval(this.Timer);
								this.date = 60;
							}
						}, 1000);

						if (this.$store.state.ISDEV == 1) {
							this.code = res.data.code.toString();
						}

						this.$message.success("发送成功");
						this.codeLoading = false;
					} else {
						this.$message.error(res.msg);
						this.codeLoading = false;
					}
				})
				.catch((err) => {
					this.codeLoading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.SettingMobile {
	@extend .w100;
	@extend .h100;

	.item {
		padding-top: 32px;

		.item-name {
			width: 200px;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			text-align: right;
			padding-right: 12px;
		}

		.item-div {
			width: 480px;

			button {
				border: none;
				border-radius: 8px;
				width: 120px;
				height: 40px;
				background-color: #ff8300;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}

			&.btn {
				margin-top: 80px;

				button {
					width: 240px;
					height: 48px;
					border-radius: 100px;
				}
			}
		}
	}

	.road {
		margin-top: 47px;

		.line {
			position: absolute;
			top: 21px;
			transform: translateY(-50%);
			width: 540px;
			border-bottom: 1px dashed #aaaaaa;
		}

		.r {
			position: relative;
			left: 0;
			top: 0;
			z-index: 1;

			div {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #bfbfbf;

				&.active {
					background-color: #ff8300;
				}
			}

			&.r_2 {
				margin: 0 210px;
			}
		}
	}
}
</style>
