<template>
	<div class="SettingPassword">
		<div class="item flex align-center">
			<span class="item-name">手机号码：</span>
			<div class="item-div">
				{{ userInfo._mobile }}
			</div>
		</div>
		<div class="item flex align-center">
			<span class="item-name">请输入验证码：</span>
			<div class="item-div flex">
				<el-input
					@change="btn"
					style="flex: 1"
					v-model="code"
					maxlength="6"
					placeholder="请输入验证码"
					clearable
				/>
				<button @click="getVerify" class="ml16">
					{{ codeText }}
				</button>
			</div>
		</div>
		<div class="item flex align-center">
			<span class="item-name">请输入登录密码：</span>
			<div class="item-div">
				<el-input
					@change="btn"
					show-password
					v-model="password"
					maxlength="12"
					placeholder="设置登录密码(6~12位数字+字母)"
					clearable
				/>
			</div>
		</div>
		<div class="item flex align-center">
			<span class="item-name">请输入确认登录密码：</span>
			<div class="item-div">
				<el-input
					@change="btn"
					show-password
					v-model="enterPassword"
					maxlength="12"
					placeholder="确认登录密码(6~12位数字+字母)"
					clearable
				/>
			</div>
		</div>
		<div class="item flex align-center">
			<span class="item-name"></span>
			<div class="item-div btn">
				<el-button :loading="loading" @click="btn">确认修改</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "SettingPassword",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
		codeText() {
			let { date } = this;
			if (date == 60) {
				return "获取验证码";
			} else {
				return date + "S 后重新获取";
			}
		},
	},

	data() {
		return {
			codeLoading: false, // 是否获取验证码中
			code: "", // 验证码
			loading: false, // 是否请求数据中
			Timer: null, // 定时器
			date: 60, // 时间

			password: "",
			enterPassword: "",
		};
	},
	created() {},
	methods: {
		btn() {
			let { userInfo, loading, code, password, enterPassword } = this;
			let { _mobile: mobile, id: user_id } = userInfo;

			if (loading) {
				return false;
			}

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}

			if (!password || !this.$validate(password, "password")) {
				this.$message.info("请输入字母加数字6-12位密码");
				return false;
			}

			if (!enterPassword || !this.$validate(enterPassword, "password")) {
				this.$message.info("请输入字母加数字6-12位确认密码");
				return false;
			}

			if (password != enterPassword) {
				this.$message.info("两次密码不一致");
				return false;
			}

			this.loading = true;
			this.$API
				.forget({
					mobile,
					code,
					password,
				})
				.then((res) => {
					if (res.code == 1) {
						this.$message.success("密码修改完成");

						this.$API
							.getUserInfo({
								user_id,
							})
							.then((ress) => {
								this.loading = false;
								if (ress.code == 1) {
									let obj = { ...userInfo, ...ress.data };
									this.$store.commit("login", obj);
									this.$emit("operate", {});
								}
							})
							.catch((err) => {
								this.loading = false;
								console.log("err", err);
							});
					} else {
						this.loading = false;
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 获取验证码
		getVerify() {
			let { userInfo, codeLoading, date } = this;
			let { _mobile: mobile, id: user_id } = userInfo;

			if (codeLoading || date != 60) {
				return false;
			}

			this.codeLoading = true;

			this.$API
				.SendVerifyCode({
					mobile,
					type: 3,
					user_id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.Timer = setInterval(() => {
							this.date = this.date - 1;

							if (this.date <= 0) {
								this.date = 0;
								clearInterval(this.Timer);
								this.date = 60;
							}
						}, 1000);

						if (this.$store.state.ISDEV == 1) {
							this.code = res.data.code.toString();
						}

						this.$message.success("发送成功");
						this.codeLoading = false;
					} else {
						this.$message.error(res.msg);
						this.codeLoading = false;
					}
				})
				.catch((err) => {
					this.codeLoading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.SettingPassword {
	@extend .w100;
	@extend .h100;

	.item {
		padding-top: 32px;

		.item-name {
			width: 200px;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			text-align: right;
			padding-right: 12px;
		}

		.item-div {
			width: 480px;

			button {
				border: none;
				border-radius: 8px;
				width: 120px;
				height: 40px;
				background-color: #ff8300;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}

			&.btn {
				margin-top: 80px;

				button {
					width: 240px;
					height: 48px;
					border-radius: 100px;
				}
			}
		}
	}
}
</style>
