<template>
	<div class="Setting bgfff br4 p32">
		<!-- 列表页 start -->
		<div v-if="curType == 'list'" class="list">
			<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
				<div class="tit-line flex">
					<span class="pr">系统设置</span>
					<span class="line">系统设置</span>
				</div>
			</div>

			<div class="item flex align-center ptb32">
				<div class="fs16 fw-7 font-33">登录密码</div>
				<span class="font-66 mlr32">|</span>
				<div class="tip fs14 font-66">
					互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。
				</div>
				<div @click="curType = 'password'" class="btn fs14">修改</div>
			</div>

			<div class="item flex align-center ptb32">
				<div class="fs16 fw-7 font-33">手机验证</div>
				<span class="font-66 mlr32">|</span>
				<div class="tip fs14 font-66">当前手机号：{{ userInfo.mobile }}</div>
				<div @click="curType = 'mobile'" class="btn fs14">修改</div>
			</div>
		</div>
		<!-- 列表页 end -->

		<!-- 修改密码 start -->
		<div v-if="curType == 'password'" class="change-pwd">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'list'"
						class="is-link"
						>系统设置</el-breadcrumb-item
					>
					<el-breadcrumb-item>修改密码</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->
			<SettingPassword @operate="operate"></SettingPassword>
		</div>
		<!-- 修改密码 end -->

		<!-- 修改手机号 start -->
		<div v-if="curType == 'mobile'" class="change-mobile">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'list'"
						class="is-link"
						>系统设置</el-breadcrumb-item
					>
					<el-breadcrumb-item>修改手机号</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->
			<SettingMobile @operate="operate"></SettingMobile>
		</div>
		<!-- 修改手机号 end -->
	</div>
</template>
<script>
import { mapState } from "vuex";
import SettingPassword from "@/components/SettingPassword";
import SettingMobile from "@/components/SettingMobile";
export default {
	name: "Setting",
	components: {
		SettingPassword,
		SettingMobile,
	},
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	data() {
		return {
			curType: "list", // 当前在那里 list 列表; password 修改密码; mobile 修改手机号;
		};
	},
	created() {
		this.$pageScrollTop();
	},
	methods: {
		operate() {
			this.curType = "list";
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		border-bottom: 1px dashed #eeeeee;

		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Setting {
	@extend .w100;
	@extend .h100;

	.list {
		.nav {
			border-bottom: 1px dashed #eeeeee;
		}

		.item {
			border-bottom: 1px solid #eeeeee;

			.tip {
				flex: 1;
			}

			.btn {
				border: 1px solid #ff8300;
				color: #ff8300;
				padding: 10px 30px;
				border-radius: 120px;
				cursor: pointer;
			}
		}
	}

	.change-pwd {
	}
}
</style>
